import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bdc2e8a0 = () => interopDefault(import('../pages/active/index.vue' /* webpackChunkName: "pages/active/index" */))
const _cea7a286 = () => interopDefault(import('../pages/bang-xep-hang/index.vue' /* webpackChunkName: "pages/bang-xep-hang/index" */))
const _2d53bde8 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _20b0d247 = () => interopDefault(import('../pages/bo-de-old/index.vue' /* webpackChunkName: "pages/bo-de-old/index" */))
const _b4128628 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _a384e32e = () => interopDefault(import('../pages/chat-ai/index.vue' /* webpackChunkName: "pages/chat-ai/index" */))
const _8bd074fc = () => interopDefault(import('../pages/dang-ky-gia-su/index.vue' /* webpackChunkName: "pages/dang-ky-gia-su/index" */))
const _11d2aaf7 = () => interopDefault(import('../pages/doi-mat-khau/index.vue' /* webpackChunkName: "pages/doi-mat-khau/index" */))
const _100c52f2 = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _78f2ce84 = () => interopDefault(import('../pages/gioi-thieu/index.vue' /* webpackChunkName: "pages/gioi-thieu/index" */))
const _97ab7508 = () => interopDefault(import('../pages/goi-cuoc/index.vue' /* webpackChunkName: "pages/goi-cuoc/index" */))
const _0ff695aa = () => interopDefault(import('../pages/hoi-bai/index.vue' /* webpackChunkName: "pages/hoi-bai/index" */))
const _74f0514e = () => interopDefault(import('../pages/hoi-bai-cua-toi/index.vue' /* webpackChunkName: "pages/hoi-bai-cua-toi/index" */))
const _01630785 = () => interopDefault(import('../pages/hoi-bai-old/index.vue' /* webpackChunkName: "pages/hoi-bai-old/index" */))
const _06a61ff3 = () => interopDefault(import('../pages/huong-dan-cong-diem/index.vue' /* webpackChunkName: "pages/huong-dan-cong-diem/index" */))
const _b5279c9a = () => interopDefault(import('../pages/huong-dan-su-dung/index.vue' /* webpackChunkName: "pages/huong-dan-su-dung/index" */))
const _7b9f2422 = () => interopDefault(import('../pages/khoa-hoc-old/index.vue' /* webpackChunkName: "pages/khoa-hoc-old/index" */))
const _5a6d876c = () => interopDefault(import('../pages/khong-ton-tai/index.vue' /* webpackChunkName: "pages/khong-ton-tai/index" */))
const _14c85da1 = () => interopDefault(import('../pages/lich-su-cong-diem/index.vue' /* webpackChunkName: "pages/lich-su-cong-diem/index" */))
const _09c6a0d0 = () => interopDefault(import('../pages/lich-su-thanh-toan/index.vue' /* webpackChunkName: "pages/lich-su-thanh-toan/index" */))
const _47be5d88 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _262751ee = () => interopDefault(import('../pages/noi-quy-hoi-bai/index.vue' /* webpackChunkName: "pages/noi-quy-hoi-bai/index" */))
const _fbeb2d74 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _47ef257c = () => interopDefault(import('../pages/tai-lieu-old/index.vue' /* webpackChunkName: "pages/tai-lieu-old/index" */))
const _03eebe56 = () => interopDefault(import('../pages/thong-tin-ca-nhan/index.vue' /* webpackChunkName: "pages/thong-tin-ca-nhan/index" */))
const _00782f56 = () => interopDefault(import('../pages/thong-tin-khac/index.vue' /* webpackChunkName: "pages/thong-tin-khac/index" */))
const _1db7fbf5 = () => interopDefault(import('../pages/thu-vien-cua-toi/index.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/index" */))
const _09bc4d62 = () => interopDefault(import('../pages/thu-vien-cua-toi-old/index.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/index" */))
const _92b0ed76 = () => interopDefault(import('../pages/tim-kiem/index.vue' /* webpackChunkName: "pages/tim-kiem/index" */))
const _2061e17f = () => interopDefault(import('../pages/xac-thuc/index.vue' /* webpackChunkName: "pages/xac-thuc/index" */))
const _25a64c44 = () => interopDefault(import('../pages/blog/search.vue' /* webpackChunkName: "pages/blog/search" */))
const _674cf7b4 = () => interopDefault(import('../pages/bo-de-old/chuyen-de/index.vue' /* webpackChunkName: "pages/bo-de-old/chuyen-de/index" */))
const _05796174 = () => interopDefault(import('../pages/bo-de-old/huong-dan.vue' /* webpackChunkName: "pages/bo-de-old/huong-dan" */))
const _3c5b4c2f = () => interopDefault(import('../pages/bo-de-old/index_old.vue' /* webpackChunkName: "pages/bo-de-old/index_old" */))
const _2f72a388 = () => interopDefault(import('../pages/event/invite/index.vue' /* webpackChunkName: "pages/event/invite/index" */))
const _04f97666 = () => interopDefault(import('../pages/gioi-thieu/mobile.vue' /* webpackChunkName: "pages/gioi-thieu/mobile" */))
const _1c718eec = () => interopDefault(import('../pages/khoa-hoc-old/index_old.vue' /* webpackChunkName: "pages/khoa-hoc-old/index_old" */))
const _07b21cac = () => interopDefault(import('../pages/tai-lieu-old/index_old.vue' /* webpackChunkName: "pages/tai-lieu-old/index_old" */))
const _ec35ab02 = () => interopDefault(import('../pages/thu-vien/chuyen-de/index.vue' /* webpackChunkName: "pages/thu-vien/chuyen-de/index" */))
const _057f70c8 = () => interopDefault(import('../pages/thu-vien/de-thi/index.vue' /* webpackChunkName: "pages/thu-vien/de-thi/index" */))
const _cde2c8de = () => interopDefault(import('../pages/thu-vien/khoa-hoc/index.vue' /* webpackChunkName: "pages/thu-vien/khoa-hoc/index" */))
const _6ee4bcd8 = () => interopDefault(import('../pages/thu-vien/search.vue' /* webpackChunkName: "pages/thu-vien/search" */))
const _72c974b1 = () => interopDefault(import('../pages/thu-vien/tai-lieu/index.vue' /* webpackChunkName: "pages/thu-vien/tai-lieu/index" */))
const _b564d384 = () => interopDefault(import('../pages/thu-vien/thu-vien-cua-toi/index.vue' /* webpackChunkName: "pages/thu-vien/thu-vien-cua-toi/index" */))
const _16f9df51 = () => interopDefault(import('../pages/thu-vien/thu-vien-cua-toi-new/index.vue' /* webpackChunkName: "pages/thu-vien/thu-vien-cua-toi-new/index" */))
const _5fc4284c = () => interopDefault(import('../pages/user/forget.vue' /* webpackChunkName: "pages/user/forget" */))
const _14ec210c = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _fe2a6760 = () => interopDefault(import('../pages/user/register.vue' /* webpackChunkName: "pages/user/register" */))
const _ceaeb5ae = () => interopDefault(import('../pages/thu-vien-cua-toi-old/bo-de/da-lam.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/bo-de/da-lam" */))
const _6ccb7b38 = () => interopDefault(import('../pages/thu-vien-cua-toi-old/bo-de/tam-dung.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/bo-de/tam-dung" */))
const _6574151a = () => interopDefault(import('../pages/thu-vien-cua-toi-old/khoa-hoc/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/khoa-hoc/dang-hoc" */))
const _da2966c6 = () => interopDefault(import('../pages/thu-vien-cua-toi-old/khoa-hoc/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/khoa-hoc/theo-doi" */))
const _1ebeb53e = () => interopDefault(import('../pages/thu-vien-cua-toi-old/tai-lieu/da-xem.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/tai-lieu/da-xem" */))
const _7569487a = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/da-lam.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/da-lam" */))
const _08ca195c = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/tam-dung.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/tam-dung" */))
const _63ac7485 = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/dang-hoc" */))
const _2951cbaf = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/theo-doi" */))
const _62a161ce = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/dang-hoc" */))
const _d756b37a = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/theo-doi" */))
const _69de4064 = () => interopDefault(import('../pages/thu-vien-cua-toi/tai-lieu/da-xem.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/tai-lieu/da-xem" */))
const _9cc39674 = () => interopDefault(import('../pages/thu-vien/de-thi/guide.vue' /* webpackChunkName: "pages/thu-vien/de-thi/guide" */))
const _4c0af91a = () => interopDefault(import('../pages/thu-vien/de-thi/lam-bai/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/lam-bai/_slug" */))
const _2b8ce925 = () => interopDefault(import('../pages/thu-vien/de-thi/xem/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/xem/_slug" */))
const _5d527b16 = () => interopDefault(import('../pages/blog/danh-muc/_slug.vue' /* webpackChunkName: "pages/blog/danh-muc/_slug" */))
const _6596566c = () => interopDefault(import('../pages/bo-de-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/bo-de-old/chuyen-de/_slug" */))
const _f7e43e0c = () => interopDefault(import('../pages/bo-de-old/ket-qua/_slug.vue' /* webpackChunkName: "pages/bo-de-old/ket-qua/_slug" */))
const _25f0ae76 = () => interopDefault(import('../pages/bo-de-old/lam-bai/_slug.vue' /* webpackChunkName: "pages/bo-de-old/lam-bai/_slug" */))
const _65fd6f70 = () => interopDefault(import('../pages/khoa-hoc-old/chi-tiet/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/chi-tiet/_slug" */))
const _2b385287 = () => interopDefault(import('../pages/khoa-hoc-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/chuyen-de/_slug" */))
const _a01e98b2 = () => interopDefault(import('../pages/tai-lieu-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/tai-lieu-old/chuyen-de/_slug" */))
const _05c9d9b9 = () => interopDefault(import('../pages/thu-vien-cua-toi-old/bo-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/bo-de/_slug" */))
const _02e9946c = () => interopDefault(import('../pages/thu-vien-cua-toi-old/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/khoa-hoc/_slug" */))
const _46b724e8 = () => interopDefault(import('../pages/thu-vien-cua-toi-old/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi-old/tai-lieu/_slug" */))
const _4108e7df = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/_slug" */))
const _4c2082cc = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/_slug" */))
const _dc8de6f4 = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/_slug" */))
const _6b73e5a6 = () => interopDefault(import('../pages/thu-vien-cua-toi/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/tai-lieu/_slug" */))
const _efa2ed92 = () => interopDefault(import('../pages/thu-vien/chuyen-de/_slug.vue' /* webpackChunkName: "pages/thu-vien/chuyen-de/_slug" */))
const _08ecb358 = () => interopDefault(import('../pages/thu-vien/de-thi/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/_slug" */))
const _d1500b6e = () => interopDefault(import('../pages/thu-vien/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/thu-vien/khoa-hoc/_slug" */))
const _7a8e6c57 = () => interopDefault(import('../pages/thu-vien/live/_slug.vue' /* webpackChunkName: "pages/thu-vien/live/_slug" */))
const _7112d369 = () => interopDefault(import('../pages/thu-vien/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien/tai-lieu/_slug" */))
const _febfba14 = () => interopDefault(import('../pages/thu-vien/video/_slug.vue' /* webpackChunkName: "pages/thu-vien/video/_slug" */))
const _30c10078 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _1efa30ff = () => interopDefault(import('../pages/bo-de-old/_slug.vue' /* webpackChunkName: "pages/bo-de-old/_slug" */))
const _4614c8f0 = () => interopDefault(import('../pages/cau-hoi/_slug.vue' /* webpackChunkName: "pages/cau-hoi/_slug" */))
const _79e882da = () => interopDefault(import('../pages/khoa-hoc-old/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/_slug" */))
const _4b5c680c = () => interopDefault(import('../pages/tai-lieu-old/_slug.vue' /* webpackChunkName: "pages/tai-lieu-old/_slug" */))
const _50f40fc0 = () => interopDefault(import('../pages/tra-loi/_slug.vue' /* webpackChunkName: "pages/tra-loi/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/active",
    component: _bdc2e8a0,
    name: "active"
  }, {
    path: "/bang-xep-hang",
    component: _cea7a286,
    name: "bang-xep-hang"
  }, {
    path: "/blog",
    component: _2d53bde8,
    name: "blog"
  }, {
    path: "/bo-de-old",
    component: _20b0d247,
    name: "bo-de-old"
  }, {
    path: "/callback",
    component: _b4128628,
    name: "callback"
  }, {
    path: "/chat-ai",
    component: _a384e32e,
    name: "chat-ai"
  }, {
    path: "/dang-ky-gia-su",
    component: _8bd074fc,
    name: "dang-ky-gia-su"
  }, {
    path: "/doi-mat-khau",
    component: _11d2aaf7,
    name: "doi-mat-khau"
  }, {
    path: "/download",
    component: _100c52f2,
    name: "download"
  }, {
    path: "/gioi-thieu",
    component: _78f2ce84,
    name: "gioi-thieu"
  }, {
    path: "/goi-cuoc",
    component: _97ab7508,
    name: "goi-cuoc"
  }, {
    path: "/hoi-bai",
    component: _0ff695aa,
    name: "hoi-bai"
  }, {
    path: "/hoi-bai-cua-toi",
    component: _74f0514e,
    name: "hoi-bai-cua-toi"
  }, {
    path: "/hoi-bai-old",
    component: _01630785,
    name: "hoi-bai-old"
  }, {
    path: "/huong-dan-cong-diem",
    component: _06a61ff3,
    name: "huong-dan-cong-diem"
  }, {
    path: "/huong-dan-su-dung",
    component: _b5279c9a,
    name: "huong-dan-su-dung"
  }, {
    path: "/khoa-hoc-old",
    component: _7b9f2422,
    name: "khoa-hoc-old"
  }, {
    path: "/khong-ton-tai",
    component: _5a6d876c,
    name: "khong-ton-tai"
  }, {
    path: "/lich-su-cong-diem",
    component: _14c85da1,
    name: "lich-su-cong-diem"
  }, {
    path: "/lich-su-thanh-toan",
    component: _09c6a0d0,
    name: "lich-su-thanh-toan"
  }, {
    path: "/maintenance",
    component: _47be5d88,
    name: "maintenance"
  }, {
    path: "/noi-quy-hoi-bai",
    component: _262751ee,
    name: "noi-quy-hoi-bai"
  }, {
    path: "/redirect",
    component: _fbeb2d74,
    name: "redirect"
  }, {
    path: "/tai-lieu-old",
    component: _47ef257c,
    name: "tai-lieu-old"
  }, {
    path: "/thong-tin-ca-nhan",
    component: _03eebe56,
    name: "thong-tin-ca-nhan"
  }, {
    path: "/thong-tin-khac",
    component: _00782f56,
    name: "thong-tin-khac"
  }, {
    path: "/thu-vien-cua-toi",
    component: _1db7fbf5,
    name: "thu-vien-cua-toi"
  }, {
    path: "/thu-vien-cua-toi-old",
    component: _09bc4d62,
    name: "thu-vien-cua-toi-old"
  }, {
    path: "/tim-kiem",
    component: _92b0ed76,
    name: "tim-kiem"
  }, {
    path: "/xac-thuc",
    component: _2061e17f,
    name: "xac-thuc"
  }, {
    path: "/blog/search",
    component: _25a64c44,
    name: "blog-search"
  }, {
    path: "/bo-de-old/chuyen-de",
    component: _674cf7b4,
    name: "bo-de-old-chuyen-de"
  }, {
    path: "/bo-de-old/huong-dan",
    component: _05796174,
    name: "bo-de-old-huong-dan"
  }, {
    path: "/bo-de-old/index_old",
    component: _3c5b4c2f,
    name: "bo-de-old-index_old"
  }, {
    path: "/event/invite",
    component: _2f72a388,
    name: "event-invite"
  }, {
    path: "/gioi-thieu/mobile",
    component: _04f97666,
    name: "gioi-thieu-mobile"
  }, {
    path: "/khoa-hoc-old/index_old",
    component: _1c718eec,
    name: "khoa-hoc-old-index_old"
  }, {
    path: "/tai-lieu-old/index_old",
    component: _07b21cac,
    name: "tai-lieu-old-index_old"
  }, {
    path: "/thu-vien/chuyen-de",
    component: _ec35ab02,
    name: "thu-vien-chuyen-de"
  }, {
    path: "/thu-vien/de-thi",
    component: _057f70c8,
    name: "thu-vien-de-thi"
  }, {
    path: "/thu-vien/khoa-hoc",
    component: _cde2c8de,
    name: "thu-vien-khoa-hoc"
  }, {
    path: "/thu-vien/search",
    component: _6ee4bcd8,
    name: "thu-vien-search"
  }, {
    path: "/thu-vien/tai-lieu",
    component: _72c974b1,
    name: "thu-vien-tai-lieu"
  }, {
    path: "/thu-vien/thu-vien-cua-toi",
    component: _b564d384,
    name: "thu-vien-thu-vien-cua-toi"
  }, {
    path: "/thu-vien/thu-vien-cua-toi-new",
    component: _16f9df51,
    name: "thu-vien-thu-vien-cua-toi-new"
  }, {
    path: "/user/forget",
    component: _5fc4284c,
    name: "user-forget"
  }, {
    path: "/user/login",
    component: _14ec210c,
    name: "user-login"
  }, {
    path: "/user/register",
    component: _fe2a6760,
    name: "user-register"
  }, {
    path: "/thu-vien-cua-toi-old/bo-de/da-lam",
    component: _ceaeb5ae,
    name: "thu-vien-cua-toi-old-bo-de-da-lam"
  }, {
    path: "/thu-vien-cua-toi-old/bo-de/tam-dung",
    component: _6ccb7b38,
    name: "thu-vien-cua-toi-old-bo-de-tam-dung"
  }, {
    path: "/thu-vien-cua-toi-old/khoa-hoc/dang-hoc",
    component: _6574151a,
    name: "thu-vien-cua-toi-old-khoa-hoc-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi-old/khoa-hoc/theo-doi",
    component: _da2966c6,
    name: "thu-vien-cua-toi-old-khoa-hoc-theo-doi"
  }, {
    path: "/thu-vien-cua-toi-old/tai-lieu/da-xem",
    component: _1ebeb53e,
    name: "thu-vien-cua-toi-old-tai-lieu-da-xem"
  }, {
    path: "/thu-vien-cua-toi/bo-de/da-lam",
    component: _7569487a,
    name: "thu-vien-cua-toi-bo-de-da-lam"
  }, {
    path: "/thu-vien-cua-toi/bo-de/tam-dung",
    component: _08ca195c,
    name: "thu-vien-cua-toi-bo-de-tam-dung"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/dang-hoc",
    component: _63ac7485,
    name: "thu-vien-cua-toi-chuyen-de-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/theo-doi",
    component: _2951cbaf,
    name: "thu-vien-cua-toi-chuyen-de-theo-doi"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/dang-hoc",
    component: _62a161ce,
    name: "thu-vien-cua-toi-khoa-hoc-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/theo-doi",
    component: _d756b37a,
    name: "thu-vien-cua-toi-khoa-hoc-theo-doi"
  }, {
    path: "/thu-vien-cua-toi/tai-lieu/da-xem",
    component: _69de4064,
    name: "thu-vien-cua-toi-tai-lieu-da-xem"
  }, {
    path: "/thu-vien/de-thi/guide",
    component: _9cc39674,
    name: "thu-vien-de-thi-guide"
  }, {
    path: "/thu-vien/de-thi/lam-bai/:slug?",
    component: _4c0af91a,
    name: "thu-vien-de-thi-lam-bai-slug"
  }, {
    path: "/thu-vien/de-thi/xem/:slug?",
    component: _2b8ce925,
    name: "thu-vien-de-thi-xem-slug"
  }, {
    path: "/blog/danh-muc/:slug?",
    component: _5d527b16,
    name: "blog-danh-muc-slug"
  }, {
    path: "/bo-de-old/chuyen-de/:slug?",
    component: _6596566c,
    name: "bo-de-old-chuyen-de-slug"
  }, {
    path: "/bo-de-old/ket-qua/:slug?",
    component: _f7e43e0c,
    name: "bo-de-old-ket-qua-slug"
  }, {
    path: "/bo-de-old/lam-bai/:slug?",
    component: _25f0ae76,
    name: "bo-de-old-lam-bai-slug"
  }, {
    path: "/khoa-hoc-old/chi-tiet/:slug?",
    component: _65fd6f70,
    name: "khoa-hoc-old-chi-tiet-slug"
  }, {
    path: "/khoa-hoc-old/chuyen-de/:slug?",
    component: _2b385287,
    name: "khoa-hoc-old-chuyen-de-slug"
  }, {
    path: "/tai-lieu-old/chuyen-de/:slug?",
    component: _a01e98b2,
    name: "tai-lieu-old-chuyen-de-slug"
  }, {
    path: "/thu-vien-cua-toi-old/bo-de/:slug?",
    component: _05c9d9b9,
    name: "thu-vien-cua-toi-old-bo-de-slug"
  }, {
    path: "/thu-vien-cua-toi-old/khoa-hoc/:slug?",
    component: _02e9946c,
    name: "thu-vien-cua-toi-old-khoa-hoc-slug"
  }, {
    path: "/thu-vien-cua-toi-old/tai-lieu/:slug?",
    component: _46b724e8,
    name: "thu-vien-cua-toi-old-tai-lieu-slug"
  }, {
    path: "/thu-vien-cua-toi/bo-de/:slug?",
    component: _4108e7df,
    name: "thu-vien-cua-toi-bo-de-slug"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/:slug?",
    component: _4c2082cc,
    name: "thu-vien-cua-toi-chuyen-de-slug"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/:slug?",
    component: _dc8de6f4,
    name: "thu-vien-cua-toi-khoa-hoc-slug"
  }, {
    path: "/thu-vien-cua-toi/tai-lieu/:slug?",
    component: _6b73e5a6,
    name: "thu-vien-cua-toi-tai-lieu-slug"
  }, {
    path: "/thu-vien/chuyen-de/:slug?",
    component: _efa2ed92,
    name: "thu-vien-chuyen-de-slug"
  }, {
    path: "/thu-vien/de-thi/:slug?",
    component: _08ecb358,
    name: "thu-vien-de-thi-slug"
  }, {
    path: "/thu-vien/khoa-hoc/:slug?",
    component: _d1500b6e,
    name: "thu-vien-khoa-hoc-slug"
  }, {
    path: "/thu-vien/live/:slug?",
    component: _7a8e6c57,
    name: "thu-vien-live-slug"
  }, {
    path: "/thu-vien/tai-lieu/:slug?",
    component: _7112d369,
    name: "thu-vien-tai-lieu-slug"
  }, {
    path: "/thu-vien/video/:slug?",
    component: _febfba14,
    name: "thu-vien-video-slug"
  }, {
    path: "/blog/:slug",
    component: _30c10078,
    name: "blog-slug"
  }, {
    path: "/bo-de-old/:slug?",
    component: _1efa30ff,
    name: "bo-de-old-slug"
  }, {
    path: "/cau-hoi/:slug?",
    component: _4614c8f0,
    name: "cau-hoi-slug"
  }, {
    path: "/khoa-hoc-old/:slug?",
    component: _79e882da,
    name: "khoa-hoc-old-slug"
  }, {
    path: "/tai-lieu-old/:slug?",
    component: _4b5c680c,
    name: "tai-lieu-old-slug"
  }, {
    path: "/tra-loi/:slug?",
    component: _50f40fc0,
    name: "tra-loi-slug"
  }, {
    path: "/",
    component: _0ff695aa,
    name: "index"
  }, {
    path: "/blog-app",
    component: _2d53bde8,
    name: "blog-app"
  }, {
    path: "/blog-app/search",
    component: _25a64c44,
    name: "blog-app-search"
  }, {
    path: "/blog-app/:slug",
    component: _30c10078,
    name: "blog-app-slug"
  }, {
    path: "/blog-app/category/:slug",
    component: _5d527b16,
    name: "blog-app-category-slug"
  }, {
    path: "/hoi-bai/:slug",
    component: _0ff695aa,
    name: "hoi-bai-slug"
  }, {
    path: "/thu-vien",
    component: _cde2c8de,
    name: "thu-vien"
  }, {
    path: "/library/quiz/guide",
    component: _9cc39674,
    name: "quiz-guide"
  }, {
    path: "/khoa-hoc",
    component: _cde2c8de,
    name: "khoa-hoc"
  }, {
    path: "/khoa-hoc/:slug",
    component: _d1500b6e,
    name: "khoa-hoc-slug"
  }, {
    path: "/video/:slug",
    component: _febfba14,
    name: "video-slug"
  }, {
    path: "/tai-lieu",
    component: _72c974b1,
    name: "tai-lieu"
  }, {
    path: "/tai-lieu/:slug",
    component: _7112d369,
    name: "tai-lieu-slug"
  }, {
    path: "/trac-nghiem",
    component: _057f70c8,
    name: "trac-nghiem"
  }, {
    path: "/trac-nghiem/:slug",
    component: _08ecb358,
    name: "trac-nghiem-slug"
  }, {
    path: "/trac-nghiem/lam-bai/:slug",
    component: _4c0af91a,
    name: "trac-nghiem-lam-bai"
  }, {
    path: "/trac-nghiem/ket-qua/:slug",
    component: _2b8ce925,
    name: "trac-nghiem-ket-qua"
  }, {
    path: "/user/login",
    component: _14ec210c,
    name: "dang-nhap"
  }, {
    path: "/user/register",
    component: _fe2a6760,
    name: "dang-ky"
  }, {
    path: "/user/forget",
    component: _5fc4284c,
    name: "quen-mat-khau"
  }, {
    path: "*",
    component: _5a6d876c
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
